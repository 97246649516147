import * as React from 'react';
import { useEffect, useState } from 'react';

import { Spinner } from '@blockworks/ui/spinner';
import { cn } from '@blockworks/ui/utils';

import { ErrorMessage } from '@/components/common';
import NewsCategoryTabs, { NewsCategoryTab } from '@/components/pages/frontpage/components/news/news-category-tabs';
import NewsSectionArticle from '@/components/pages/frontpage/components/news/news-section-article';
import { useAlgolia } from '@/context/algolia';
import type { ArticleType } from '@/types/article-type';
import mapAlgoliaHits from '@/utils/functions/algolia/map-algolia-hits';
import queryAlgoliaPosts from '@/utils/functions/algolia/query-algolia-posts';

type NewsSectionProps = {
    className?: string;
    excludePostIds?: number[];
    articles: ArticleType[];
    categoryTabs: NewsCategoryTab[];
    onCategoryChange?: (category: NewsCategoryTab) => void;
};

const NewsSection = (props: NewsSectionProps) => {
    const { className, categoryTabs, onCategoryChange } = props;
    const [selectedCategorySlug, setSelectedCategorySlug] = useState<string | null>(null);
    const [articles, setArticles] = useState<ArticleType[]>(props.articles.slice(5, 11));
    const [isLoadingArticles, setIsLoadingArticles] = useState(false);
    const { algoliaKey, error, loading } = useAlgolia();

    useEffect(() => {
        (async () => {
            if (selectedCategorySlug === null || !algoliaKey) {
                return;
            }

            setIsLoadingArticles(true);
            try {
                const res = await queryAlgoliaPosts(
                    '',
                    6,
                    1,
                    {
                        filters: `taxonomies.category:${selectedCategorySlug}`,
                    },
                    algoliaKey,
                );

                setArticles(
                    mapAlgoliaHits(res.hits).filter(
                        article => !(props.excludePostIds || []).includes(Number(article.id)),
                    ),
                );
            } catch (error) {
                console.error('Failed to fetch articles:', error);
            } finally {
                setIsLoadingArticles(false);
            }
        })();
    }, [algoliaKey, props.excludePostIds, selectedCategorySlug]);

    const isLoadingNewsSection = isLoadingArticles || loading;

    return (
        <div
            className={cn(
                'flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-5 px-10 pb-5 w-full',
                className,
            )}
        >
            <div>
                <NewsCategoryTabs
                    tabs={categoryTabs}
                    onClickTab={tab => {
                        setSelectedCategorySlug(tab.slug);
                        onCategoryChange && onCategoryChange(tab);
                    }}
                />
            </div>
            {!isLoadingNewsSection && (
                <div className="mt-0 md:mt-6 lg:mt-12 grid gap-8 grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-6">
                    {articles.map(article => (
                        <NewsSectionArticle article={article} key={`NewsSectionArticle-${article.slug}`} />
                    ))}
                </div>
            )}
            {isLoadingNewsSection && (
                <div className="mt-12 h-80 w-full">
                    <div className="mx-auto">
                        <Spinner />
                    </div>
                </div>
            )}

            {error && !isLoadingNewsSection && (
                <ErrorMessage title="Something went wrong" subtitle="Please try again later" />
            )}
        </div>
    );
};

export default NewsSection;
