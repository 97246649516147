import React from 'react';

interface ErrorMessageProps {
    title: string;
    subtitle?: string;
}

const ErrorMessage = ({ title, subtitle }: ErrorMessageProps) => {
    return (
        <div className="flex flex-col items-center justify-center p-10 rounded-lg text-center">
            <svg
                className="w-12 h-12 mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v2m0 4h.01M21 12c0 4.971-4.029 9-9 9s-9-4.029-9-9 4.029-9 9-9 9 4.029 9 9z"
                />
            </svg>
            <h2 className="text-xl font-bold">{title}</h2>
            {subtitle && <p className="mt-2 text-sm ">{subtitle}</p>}
        </div>
    );
};

export { ErrorMessage };
